import { Link } from "react-router-dom";
// @mui
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
// utils
// import { fShortenNumber } from 'src/utils/format-number';
// _mock
// import { _socials } from 'src/_mock';
// assets
import { AvatarShape } from "../assets/illustrations/index";
// components
import Image from "../customizedComponents/image/index";
// import Iconify from '../../../src/components/Iconify';

// ----------------------------------------------------------------------

const FLAGS = [
  {
    name: "Austria",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849717/EU%20flags/Austria_wuqqzl.png",
  },
  {
    name: "Belgium",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849717/EU%20flags/belgium_nkrsdt.png",
  },
  {
    name: "Bulgaria",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849717/EU%20flags/Bulgaria_pjoag4.png",
  },
  {
    name: "Croatia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849718/EU%20flags/Croatia_grkmtc.png",
  },
  {
    name: "Cyprus",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849718/EU%20flags/Cyprus_v3rql3.png",
  },
  {
    name: "Czech Republic",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849720/EU%20flags/Czech_Republic_kdrhdo.png",
  },
  {
    name: "Czechia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849720/EU%20flags/Czech_Republic_kdrhdo.png",
  },
  {
    name: "Denmark",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849720/EU%20flags/Denmark_wyhsob.png",
  },
  {
    name: "Estonia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849721/EU%20flags/Estonia_muswrh.png",
  },
  {
    name: "Finland",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849722/EU%20flags/Finland_lt2pig.png",
  },
  {
    name: "France",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849723/EU%20flags/France_ab2foq.png",
  },
  {
    name: "Germany",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849724/EU%20flags/Germany_wxdwwe.png",
  },
  {
    name: "Greece",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849724/EU%20flags/Greece_pctcjm.png",
  },
  {
    name: "Hungary",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849725/EU%20flags/HUngary_duhwii.png",
  },
  {
    name: "Ireland",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849726/EU%20flags/Ireland_tcrte8.png",
  },
  {
    name: "Italy",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849727/EU%20flags/Italy_pls9mk.png",
  },
  {
    name: "Latvia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849728/EU%20flags/Latvia_vzdkwo.png",
  },
  {
    name: "Lithuania",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849729/EU%20flags/Lithuania_v7ibxh.png",
  },
  {
    name: "Luxembourg",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849730/EU%20flags/Luxembourg_xccng6.png",
  },
  {
    name: "Malta",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849731/EU%20flags/Malta_gwnygh.png",
  },
  {
    name: "Netherlands",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849732/EU%20flags/Netherland_kwsyct.png",
  },
  {
    name: "Poland",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849733/EU%20flags/Poland_i2ujxz.png",
  },
  {
    name: "Portugal",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849734/EU%20flags/Portugal_dvevr1.png",
  },
  {
    name: "Romania",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849735/EU%20flags/ROmania_fwhqwj.png",
  },
  {
    name: "Slovakia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849736/EU%20flags/Slovakia_jlsdt6.png",
  },
  {
    name: "Slovenia",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849737/EU%20flags/Slovenia_uqmuov.png",
  },
  {
    name: "Spain",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849738/EU%20flags/Spain_to7zux.png",
  },
  {
    name: "Sweden",
    flag: "https://res.cloudinary.com/dojo-dev/image/upload/v1724849738/EU%20flags/Sweden_hr04eq.png",
  },
];

export default function IdentiKitCard({ term, user }) {
  const theme = useTheme();

  const matchedFlag = FLAGS.find((flagItem) => flagItem.name === user?.country);

  // const { contacts, country, group, mp, party, id } = user;

  return (
    <Card
      sx={{
        textAlign: "center",
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <AvatarShape
          sx={{
            left: 0,
            right: 0,
            zIndex: 10,
            mx: "auto",
            bottom: -29,

            position: "absolute",
            // borderRadius: '0 0 100% 100%',
          }}
        />
        <Link
          to={`/MEP-Info/${term}/${user?.mepID}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Avatar
            alt={user?.mp?.name}
            src={user?.mp?.imgLink}
            sx={{
              width: 64,
              height: 64,
              zIndex: 15,
              margin: 1,
              left: 0,
              right: 0,
              bottom: -42,
              mx: "auto",
              position: "absolute",
            }}
          />
        </Link>
        <Image
          src={matchedFlag?.flag}
          alt={user?.country}
          ratio="16/9"
          overlay={alpha(theme.palette.grey[900], 0.48)}
        />
      </Box>

      <ListItemText
        sx={{ mt: 7, mb: 0 }}
        primary={
          <Link
            to={`/MEP-Info/${term}/${user?.mepID}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {user?.mp?.name}
          </Link>
        }
        secondary={user?.country}
        primaryTypographyProps={{ typography: "subtitle1" }}
        secondaryTypographyProps={{ component: "span", mt: 0.2 }}
      />

      <Stack p={2} spacing={1}>
        <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}>
          <span
            style={{ color: "#0C4767", fontSize: "14px", fontWeight: "600" }}
          >
            {" "}
            Group:{" "}
          </span>
          {user?.group}
        </Typography>
        <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}>
          <span
            style={{ color: "#0C4767", fontSize: "14px", fontWeight: "600" }}
          >
            Party:{" "}
          </span>
          {user?.party}
        </Typography>
      </Stack>

    </Card>
  );
}
