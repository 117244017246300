import React, { useState, useRef } from "react";
import MPInfo from "../../customizedComponents/MPInfo";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { CircularProgress, Box, Button, lighten } from "@mui/material";
import { buildURL, rootAPI, callAPI } from "../../api/callAPI";
import PromptsQuerySelectorsGradeSheet from "customizedComponents/PromptQuerySelectorsGradeSheet";
import { useNavigate } from "react-router-dom";

const ITEMS_PER_LOAD = 10;

const GradeSheets = () => {
  const [LLMOutput, setLLMOutput] = useState([]);
  const [visibleItems, setVisibleItems] = useState(ITEMS_PER_LOAD);
  const [loading, setLoading] = useState(false);
  const [allItemsLoaded, setAllItemsLoaded] = useState(false);
  const [sortCriteria, setSortCriteria] = useState("totalInterventions");
  const [sortOrder, setSortOrder] = useState("desc");
  const cache = useRef({});
  const navigate = useNavigate()
  const fetchLLMResponses = async (queryID) => {
    setLoading(true);
    let LLMResponses = [];
    try {
      if (!cache.current[queryID]) {
        const data = await callAPI(
          buildURL(rootAPI, `user/grade_sheets?queryID=${queryID}`),
          "GET",
          null,
          navigate
        );
        LLMResponses = await data.response;
        LLMResponses = Array.isArray(LLMResponses) ? LLMResponses : [];
        cache.current[queryID] = LLMResponses;
      } else {
        LLMResponses = cache.current[queryID];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLLMOutput(LLMResponses);
      setVisibleItems(ITEMS_PER_LOAD);
      setAllItemsLoaded(LLMResponses.length <= ITEMS_PER_LOAD);
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => {
      const newVisibleCount = prevVisibleItems + ITEMS_PER_LOAD;
      if (newVisibleCount >= LLMOutput.length) {
        setAllItemsLoaded(true);
      }
      return newVisibleCount;
    });
  };

  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortCriteria(criteria);
      setSortOrder("desc");
    }
  };

  const sortedLLMOutput = [...LLMOutput].sort((a, b) => {
    const compareValue = (criteria) => {
      if (criteria === "totalInterventions") {
        return a.totalInterventions - b.totalInterventions;
      } else if (criteria === "levelOfConcern") {
        console.log("CAIO");
        return a.avgLevelOfConcern - b.avgLevelOfConcern;
      }
      return 0;
    };
    return sortOrder === "asc"
      ? compareValue(sortCriteria)
      : -compareValue(sortCriteria);
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ width: "100%" }}>
        <PromptsQuerySelectorsGradeSheet
          onQueryUpdate={fetchLLMResponses}
          handleSort={handleSort}
          sortCriteria={sortCriteria}
          sortOrder={sortOrder}
        />
      </Box>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {sortedLLMOutput.slice(0, visibleItems).map((mp, index) => (
            <Box sx={{ width: "100%" }}>
              <MPInfo
                bioData={{
                  name: mp.name,
                  imgLink: mp.imgLink,
                  instagram: mp.instagram,
                  facebook: mp.facebook,
                  website: mp.website,
                  homePage: mp.homePage,
                  email: mp.email,
                  party: mp.party,
                  group: mp.group,
                  levelOfConcern: mp.avgLevelOfConcern,
                  country: mp.country,
                  totalInterventions: mp.totalInterventions,
                  impactScore: mp.impactScore
                }}
                interventions={{
                  questions: mp.questions,
                  explanationsOfVote: mp.explanationsOfVote,
                  debates: mp.debates,
                }}
              />
            </Box>
          ))}

          {!allItemsLoaded && (
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                sx={{
                  background:
                    " linear-gradient(256deg, #08344C 0%, #0C4767 100%)",

                  "&:hover": {
                    background: `linear-gradient(256deg, ${lighten(
                      "#08344C",
                      0.1
                    )} 0%, ${lighten("#0C4767", 0.1)} 100%)`,
                  },
                }}
                variant="contained"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            </Box>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default GradeSheets;
