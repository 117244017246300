// DrawerList.js
import React from "react";
import { Box, Typography, IconButton, TextField, Autocomplete, Slider, Tooltip } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Iconify from "layouts/user-details/Iconify";

const CustomDrawer = ({
  open,
  toggleDrawer,
  filterStartDate,
  setFilterStartDate,
  filterEndDate,
  setFilterEndDate,
  availableMPs,
  filterMP,
  setFilterMP,
  availableParties,
  filterParty,
  setFilterParty,
  availableGroups,
  filterGroup,
  setFilterGroup,
  filterTypeDocument,
  setFilterTypeDocument,
  filterTerm,
  setFilterTerm,
  setFilterTermValue,
  filterConcernRange,
  handleConcernChange,
  filterRelevanceScore,
  handleRelevanceChange,
}) => (
    <Drawer
    open={open}
    onClose={toggleDrawer(false)}
    anchor="right"
    sx={{
      "& .MuiDrawer-paper": {
        backgroundColor: "#fff",
        margin: 0,
        borderRadius: 0,
        height: "100vh",
        width: 350,
      },
    }}
  >
    <Box sx={{ display: "flex", flexDirection: "column", mt: 2, p: 2 }}>
        <Box
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pb: 1,
        }}
        >
        <Typography
            variant="body2"
            paragraph
            sx={{ fontSize: "1.25rem", fontWeight: "600", color: "#344767" }}
        >
            Filters
        </Typography>
        <Box>
            <Iconify
            icon="gridicons:cross-small"
            width={24}
            sx={{ color: "#757575", mr: 2, cursor: "pointer" }}
            onClick={toggleDrawer(false)}
            />
        </Box>
        </Box>

        {/* Start Date */}
        <TextField
        label="Start Date"
        type="date"
        value={filterStartDate}
        onChange={(e) => setFilterStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
        />

        {/* End Date */}
        <TextField
        label="End Date"
        type="date"
        value={filterEndDate}
        onChange={(e) => setFilterEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
        />

        {/* MP Filter */}
        <Autocomplete
        options={availableMPs}
        getOptionLabel={(option) => option}
        value={filterMP}
        onChange={(e, newValue) => setFilterMP(newValue || "")}
        renderInput={(params) => (
            <TextField {...params} label="MP" variant="outlined" />
        )}
        sx={{ mb: 2 }}
        />

        {/* Party Filter */}
        <Autocomplete
        options={availableParties}
        getOptionLabel={(option) => option}
        value={filterParty}
        onChange={(e, newValue) => setFilterParty(newValue || "")}
        renderInput={(params) => (
            <TextField {...params} label="Party" variant="outlined" />
        )}
        sx={{ mb: 2 }}
        />

        {/* Group Filter */}
        <Autocomplete
        options={availableGroups}
        getOptionLabel={(option) => option}
        value={filterGroup}
        onChange={(e, newValue) => setFilterGroup(newValue || "")}
        renderInput={(params) => (
            <TextField {...params} label="Group" variant="outlined" />
        )}
        sx={{ mb: 2 }}
        />

        {/* Type of Document */}
        <Autocomplete
        options={["Questions", "Debates", "Explanations"]}
        getOptionLabel={(option) => option}
        value={filterTypeDocument}
        onChange={(e, newValue) => setFilterTypeDocument(newValue || "")}
        renderInput={(params) => (
            <TextField {...params} label="Type of Document" variant="outlined" />
        )}
        sx={{ mb: 2 }}
        />

        {/* Term Filter */}
        <Autocomplete
        options={["All", "Currently sitting MEPs", "Past MEPs"]}
        getOptionLabel={(option) => option}
        value={filterTerm}
        onChange={(e, newValue) => {
            if (newValue === "Currently sitting MEPs") {
            setFilterTerm(newValue);
            setFilterTermValue(10);
            } else if (newValue === "Past MEPs") {
            setFilterTerm(newValue);
            setFilterTermValue(9);
            } else {
            setFilterTerm(newValue);
            setFilterTermValue(9);
            }
        }}
        renderInput={(params) => (
            <TextField {...params} label="Term" variant="outlined" />
        )}
        sx={{ mb: 2 }}
        />

        {/* Level of Concern */}
        <Box sx={{ mb: 2 }}>
        <Typography
            gutterBottom
            sx={{ fontSize: "0.875rem", fontWeight: "600", color: "#000000" }}
        >
            Level of Concern
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip
            title={`Min: ${filterConcernRange && filterConcernRange[0]}`}
            placement="top"
            >
            <Box sx={{ width: 50, textAlign: "center" }}>
                <Typography variant="caption">
                {filterConcernRange && filterConcernRange[0]}
                </Typography>
            </Box>
            </Tooltip>
            <Slider
            value={filterConcernRange}
            onChange={handleConcernChange}
            valueLabelDisplay="auto"
            min={0}
            max={100}
            sx={{ width: 300, mx: 2 }}
            />
            <Tooltip
            title={`Max: ${filterConcernRange && filterConcernRange[1]}`}
            placement="top"
            >
            <Box sx={{ width: 50, textAlign: "center" }}>
                <Typography variant="caption">
                {filterConcernRange && filterConcernRange[1]}
                </Typography>
            </Box>
            </Tooltip>
        </Box>
        </Box>

        {/* Relevance Score */}
        <Box sx={{ mb: 2 }}>
        <Typography
            gutterBottom
            sx={{ fontSize: "0.875rem", fontWeight: "600", color: "#000000" }}
        >
            Relevance Score
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip
            title={`Min: ${filterRelevanceScore && filterRelevanceScore[0]}`}
            placement="top"
            >
            <Box sx={{ width: 50, textAlign: "center" }}>
                <Typography variant="caption">
                {filterRelevanceScore && filterRelevanceScore[0]}
                </Typography>
            </Box>
            </Tooltip>
            <Slider
            value={filterRelevanceScore}
            onChange={handleRelevanceChange}
            valueLabelDisplay="auto"
            min={0}
            max={1}
            step={0.05}
            sx={{ width: 300, mx: 2 }}
            />
            <Tooltip
            title={`Max: ${filterRelevanceScore && filterRelevanceScore[1]}`}
            placement="top"
            >
            <Box sx={{ width: 50, textAlign: "center" }}>
                <Typography variant="caption">
                {filterRelevanceScore && filterRelevanceScore[1]}
                </Typography>
            </Box>
            </Tooltip>
        </Box>
        </Box>
    </Box>
</Drawer>

);

export default CustomDrawer;
