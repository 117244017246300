import { getFreshToken } from "./authentication";

const rootAPI = "https://united-wavelet-422322-m7.uc.r.appspot.com/";
// const rootAPI = "http://127.0.0.1:5000";
const callAPI = async (url, method, body = null, navigate) => {
  try {
    const options = {
      method: method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (body) {
      options.body = body;
    }

    const token = await getFreshToken(); // Refresh token if needed
    // retrieve token from local storage
    if (token) {
      // include token to headers
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, options);
    if (response.status === 401) {
      // Handle token expiration
      console.warn('Token has expired. Redirecting to login...');
      navigate("/authentication/sign-in");
      return; // Exit the function early since we're redirecting
    }
    if (response.status === 403){
      throw new Error(`Authentication failed`);

    }
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data; // Return data for further handling
  } catch (error) {
    throw new Error(`Error accessing API: ${error.message}`);

  }
};


// Function to build URL
const buildURL = (root, path) => {
  if (!root.endsWith("/")) {
    root += "/";
  }
  if (path.startsWith("/")) {
    path = path.substring(1);
  }
  return root + path;
};

// Configuration of APIs with names and paths
const apis = (rootAPI) => ({
  welcome: buildURL(rootAPI, ""),
  showParties: buildURL(rootAPI, "parliament/parties"),
  showLLM: buildURL(
    rootAPI,
    `user/responses?queryID=c20ea61e-9d48-5699-ad83-9f402017180a&typeDocument=questions`
  ),
  showMP: buildURL(rootAPI, `MEPs/mep_info`),
  showPrompts: buildURL(rootAPI, "admin/prompts"),
  showQueries: buildURL(rootAPI, "admin/queries"),
});

export { callAPI, buildURL, apis, rootAPI };
