import { useEffect, useState, useCallback, useRef } from "react";
// @mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ChatNav from "../chat-nav";
import ChatMessageList from "../chat-message-list";
import ChatMessageInput from "../chat-message-input";
import { rootAPI,buildURL,callAPI } from "api/callAPI";
import { Box } from "@mui/material";
import EmptyChat from "../../../assets/images/illustrations/ic-chat-active.svg";
import Image from "customizedComponents/image";
import { cloneDeep } from "lodash";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
export default function ChatView() {
  // const router = useRouter();
  const cache = useRef({});
  const [LLMOutput, setLLMOutput] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [newmessages] = useState([]);
  const navigate = useNavigate();
  const handleNewMessage = (newMessage) => {
    const messagesArray = cloneDeep(LLMOutput);

    // Add user message immediately
    const userMessage = {
      author: "human",
      chatID: "12345sd01",
      chatTitle: "test_chat",
      content: newMessage,
      messageID: "b6a87b41-da47-51b5-8b56-098193c8e29c",
      timestamp: Math.floor(Date.now() / 1000),
      temp: true,
    };

    // Add the user message to the array
    messagesArray.push(userMessage);
    setLLMOutput(messagesArray);

    // Simulate bot typing
    setTimeout(() => {
      const typingMessage = {
        author: "ai",
        chatID: "12345sd01",
        chatTitle: "test_chat",
        content: "Processing...",
        messageID: "b6a87b41-da47-51b5-8b56-098193c8e29c",
        timestamp: Math.floor(Date.now() / 1000),
        temp: true,
      };

      // Add typing message to the array
      const updatedMessagesArray = cloneDeep(messagesArray);
      updatedMessagesArray.push(typingMessage);
      setLLMOutput(updatedMessagesArray);
    }, 1000); // Simulate delay for typing
  };

  const fetchLLMResponses = async (chatID) => {
    let LLMResponses = [];
    try {
      const body_request = JSON.stringify({ chat_id: chatID });
      const response = await callAPI(
        buildURL(rootAPI, "chat/retrieve_history"),
        "POST",
        body_request,
        navigate
      );

      if (!response) {
        console.error("Error fetching data:"); // Capture response error details
      }


      LLMResponses = response.response || []; // Extract and handle data
      cache.current[chatID] = LLMResponses;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Update state in the finally block to ensure it runs regardless of success or failure
      setLLMOutput(LLMResponses);
    }
  };

  useEffect(() => {
    if (selectedConversationId) {
      fetchLLMResponses(selectedConversationId);
    }
  }, [selectedConversationId]);
  const [recipients, setRecipients] = useState([]);

  const handleAddRecipients = useCallback((selected) => {
    setRecipients(selected);
  }, []);

  const renderNav = (
    <ChatNav
      cache={cache}
      contacts={[]}
      conversations={[]}
      loading={false}
      selectedConversationId={selectedConversationId}
      onSelectConversation={setSelectedConversationId}
      fetchLLMResponses={fetchLLMResponses}
    />
  );

  const renderMessages = (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      {LLMOutput && LLMOutput.length > 0 ? (
        <ChatMessageList
          messages={LLMOutput}
          participants={[]} // Make sure this matches the expected format
          fetchLLMResponses={fetchLLMResponses}
          newmessages={newmessages}
        />
      ) : (

        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Stack items vertically
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Ensure Box takes the full height of the parent
          }}
        >
          <Image
            src={EmptyChat}
            style={{ width: "150px", marginBottom: "16px" }} // Adjust width and margin as needed
          />
          <Typography align="center">
            Please choose a chat from history or <br /> start a new chat!
          </Typography>
        </Box>
      )}

      <ChatMessageInput
        recipients={recipients}
        onAddRecipients={handleAddRecipients}
        selectedConversationId={selectedConversationId}
        onSelectConversation={setSelectedConversationId}
        disabled={!recipients.length && !selectedConversationId}
        fetchLLMResponses={fetchLLMResponses}
        handleNewMessage={handleNewMessage}
      />
    </Stack>
  );

  return (
    // <Container maxWidth={settings.themeStretch ? false : "xl"}>
    <Container maxWidth={"xl"} sx={{ overflowY: "hidden", maxHeight: "78vh" }}>

      <Stack component={Card} direction="row" sx={{ height: "75vh" }}>
        {renderNav}

        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: "hidden",
          }}
        >
          {/* {renderHead} */}

          <Stack
            direction="row"
            sx={{
              width: 1,
              height: 1,
              overflow: "hidden",
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            {renderMessages}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
