import { useState, useEffect, useRef } from "react";
// @mui
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import ProfileHome from "./ProfileHome";
import ProfileCover from "./ProfileCover";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { callAPI, buildURL, rootAPI } from "api/callAPI";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function UserProfileView() {
  // const [searchFriends, setSearchFriends] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [currentTab, setCurrentTab] = useState("questions");
  const [mep, setMep] = useState(null);
  const [sortCriteria, setSortCriteria] = useState("totalInterventions");
  const [sortOrder, setSortOrder] = useState("desc");
  const cache = useRef({});
  let { term, id } = useParams();
  const navigate = useNavigate();
  const fetchLLMResponses = async (queryID) => {
    let LLMResponses = [];
    try {
      if (!cache.current[queryID]) {
        const data = await callAPI(
          buildURL(rootAPI, `user/grade_sheets?queryID=${queryID}`),
          "GET",
          null,
          navigate
        );
        LLMResponses = await data.response;
        LLMResponses = Array.isArray(LLMResponses) ? LLMResponses : [];
        cache.current[queryID] = LLMResponses;
      } else {
        LLMResponses = cache.current[queryID];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await callAPI(
          buildURL(rootAPI, `MEPs/mep_info?term=${term}&mepID=${id}`),
          "GET",
          null,
          navigate
        );
        setMep(data?.response[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortCriteria(criteria);
      setSortOrder("desc");
    }
  };


  if (!mep) return null;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="lg">
        <Card
          sx={{
            mt: 12,
            mb: 3,
            height: 290,
          }}
        >
          <ProfileCover data={mep} />
        </Card>

        <ProfileHome
          tabValues={currentTab}
          info={mep}
          onQueryUpdate={fetchLLMResponses}
          handleSort={handleSort}
          sortCriteria={sortCriteria}
          sortOrder={sortOrder}
        />
      </Container>
    </DashboardLayout>
  );
}
