import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
// @mui
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import CardHeader from "@mui/material/CardHeader";
// _mock
// import { _socials } from 'src/_mock';
// utils
// import { fNumber } from 'src/utils/format-number';
// components
import Iconify from "./Iconify";
import FeedItemProfile from "layouts/feed/FeedItemProfile";
import { callAPI } from "api/callAPI";
import { buildURL } from "api/callAPI";
import { rootAPI } from "api/callAPI";
import { Typography } from "@mui/material";

export default function ProfileHome({ info, posts, onQueryUpdate, tabValues }) {
  const [sortCriteria, setSortCriteria] = useState("totalInterventions");
  const [sortOrder, setSortOrder] = useState("desc");
  const [LLMOutput, setLLMOutput] = useState(null);
  const [members, setMembers] = useState(null);
  const cache = useRef({});

  const fetchLLMResponses = async (queryID) => {
    let LLMResponses = [];
    try {
      // if (!cache.current[queryID]) {
      const data = await callAPI(
        buildURL(
          rootAPI,
          `user/grade_sheets?queryID=${queryID}&mepID=${info.mepID}`
        ),
        "GET"
      );
      LLMResponses = await data.response;
      LLMResponses = Array.isArray(LLMResponses) ? LLMResponses : [];
      cache.current[queryID] = LLMResponses;
      // } else {
      //   LLMResponses = cache.current[queryID];
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLLMOutput(LLMResponses[0]);
    }
  };

  const fetchMembers = async (queryID) => {
    try {
      // if (!cache.current[queryID]) {
      const data = await callAPI(
        buildURL(
          rootAPI,
          `MEPs/mep_roles?mepID=${info.mepID}&term=${info.term}`
        ),
        "GET"
      );
      const MemberResponse = await data.response;
      setMembers(MemberResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (criteria) => {
    if (sortCriteria === criteria) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortCriteria(criteria);
      setSortOrder("desc");
    }
  };

  const renderFollows = (
    <Card sx={{ p: 3 }}>
      <Stack direction="row">
        <Stack width={1} direction="column" spacing={1}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="span"
              sx={{
                color: "#212B36",
                typography: "body2",
                fontWeight: "600",
                width: "80%",
              }}
            >
              Average Level Of Concern
            </Box>
            <Divider
              orientation="vertical"
              sx={{
                borderColor: "black",
                borderStyle: "dashed",
                height: "auto",
                alignSelf: "stretch",
              }}
            />
            <Box
              component="span"
              sx={{
                color: "#537E3A",
                typography: "body2",
                fontWeight: "400",
                width: "20%",
              }}
            >
              {LLMOutput?.avgLevelOfConcern ? Math.round(LLMOutput?.avgLevelOfConcern) : "N/A"}
            </Box>
          </Box>
          <Box
            sx={{
              with: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="span"
              sx={{
                color: "#212B36",
                typography: "body2",
                fontWeight: "600",
                width: "80%",
              }}
            >
              Total Interventions
            </Box>
            <Divider
              orientation="vertical"
              sx={{
                borderColor: "black",
                borderStyle: "dashed",
                height: "auto",
                alignSelf: "stretch",
              }}
            />
            <Box
              component="span"
              sx={{
                color: "#537E3A",
                typography: "body2",
                fontWeight: "400",
                width: "20%",
              }}
            >
              {LLMOutput ? LLMOutput?.totalInterventions : 0}
            </Box>
          </Box>

          <Box
            sx={{
              with: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="span"
              sx={{
                color: "#212B36",
                typography: "body2",
                fontWeight: "600",
                width: "80%",
              }}
            >
              Impact Score
            </Box>
            <Divider
              orientation="vertical"
              sx={{
                borderColor: "black",
                borderStyle: "dashed",
                height: "auto",
                alignSelf: "stretch",
              }}
            />
            <Box
              component="span"
              sx={{
                color: "#537E3A",
                typography: "body2",
                fontWeight: "400",
                width: "20%",
              }}
            >
              {info?.impactScore}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );

  const renderAbout = (
    <Card>
      <CardHeader title="About" sx={{ color: "#212B36" }} />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" spacing={2}>
          <Iconify
            icon="vaadin:group"
            width={24}
            style={{ color: "#757575" }}
          />
          {/* <Iconify icon="dashicons:groups" width={24}  /> */}

          {info.group && (
            <Box
              sx={{
                fontSize: "0.875rem",
                maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
                fontWeight: "400",
              }}
            >
              <Typography sx={{ fontSize: { xs: "12px", xl: "14px" } }}>
                {info.group}
              </Typography>
              <Link variant="subtitle2" color="inherit">
                {/* {info.country} */}
              </Link>
            </Box>
          )}
        </Stack>

        <Stack direction="row" sx={{ typography: "body2" }}>
          <Iconify
            icon="heroicons:user-group-20-solid"
            width={24}
            sx={{ color: "#757575", mr: 2 }}
          />

          {info.party && (
            <Box
              sx={{
                fontSize: "0.875rem",
                maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
                fontWeight: "400",
              }}
            >
              <Typography sx={{ fontSize: { xs: "12px", xl: "14px" } }}>
                {info.party}
              </Typography>
            </Box>
          )}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Iconify
            icon="mingcute:location-fill"
            width={24}
            sx={{ color: "#757575" }}
          />

          <Box
            sx={{
              fontSize: "0.875rem",
              maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
              fontWeight: "400",
            }}
          >
            <Typography sx={{ fontSize: { xs: "12px", xl: "14px" } }}>
              {info.country}
            </Typography>
          </Box>
        </Stack>

        {info.email && (
          <Stack direction="row">
            <Iconify
              icon="mingcute:mail-line"
              width={24}
              sx={{ color: "#757575", minWidth: 24, mr: 1 }}
            />
            {info.email && (
              <Box
                sx={{
                  fontSize: "0.875rem",
                  maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
                  fontWeight: "400",
                }}
              >
                <Typography
                  sx={{ fontSize: { xs: "12px", xl: "14px" } }}
                  component="a"
                  href={`mailto:${info.email}`}
                  style={{ textDecoration: "none", color: "inherit" }} // Optional: styling to make the email look like normal text
                >
                  {info.email}
                </Typography>
              </Box>
            )}
          </Stack>
        )}

        <Stack direction="row" spacing={2}>
          <Iconify
            icon="solar:case-minimalistic-bold"
            width={24}
            sx={{ color: "#757575" }}
          />

          {info.role && (
            <Box
              sx={{
                fontSize: "0.875rem",
                maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
                fontWeight: "400",
              }}
            >
              <Typography sx={{ fontSize: { xs: "12px", xl: "14px" } }}>
                {info.role}
              </Typography>
            </Box>
          )}
        </Stack>
        {info.facebook && (
          <Stack direction="row" spacing={2}>
            <Iconify
              icon="ic:twotone-facebook"
              width={24}
              sx={{ color: "#757575", minWidth: 24 }}
            />
            <Box
              sx={{
                fontSize: "0.875rem",
                maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
                fontWeight: "400",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "12px", xl: "14px" } }}
                component="a"
                href={info.facebook} // Use the Facebook profile URL
                target="_blank" // Open the profile in a new tab
                rel="noopener noreferrer" // Security measure to prevent access to the window object
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }} // Optional styling
              >
                {info.facebook}
              </Typography>
            </Box>
          </Stack>
        )}

        {info.instagram && (
          <Stack direction="row" spacing={2}>
            <Iconify
              icon="lets-icons:insta-fill"
              width={24}
              sx={{ color: "#757575", minWidth: 24 }}
            />

            <Box
              sx={{
                fontSize: "0.875rem",
                maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
                fontWeight: "400",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "12px", xl: "14px" } }}
                component="a"
                href={info.instagram} // Use the Facebook profile URL
                target="_blank" // Open the profile in a new tab
                rel="noopener noreferrer" // Security measure to prevent access to the window object
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                {info.instagram}
              </Typography>
            </Box>
          </Stack>
        )}

        {info.linkedin && (
          <Stack direction="row" spacing={2}>
            <Iconify
              icon="eva:linkedin-fill"
              width={24}
              sx={{ color: "#757575", minWidth: 24 }}
            />

            <Box
              sx={{
                fontSize: "0.875rem",
                maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
                fontWeight: "400",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "12px", xl: "14px" } }}
                component="a"
                href={info.linkedin} // Use the Facebook profile URL
                target="_blank" // Open the profile in a new tab
                rel="noopener noreferrer" // Security measure to prevent access to the window object
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }} // Optional styling
              >
                {info.linkedin}
              </Typography>
            </Box>
          </Stack>
        )}

        <Stack direction="row" spacing={2}>
          <Iconify
            icon="pepicons-pencil:persons-circle-filled"
            width={24}
            sx={{ color: "#757575" }}
          />
          <Box
            sx={{
              fontSize: "0.875rem",
              maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
              fontWeight: "400",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "14px",
                  xl: "16px",
                },
                fontWeight: "bold",
                // marginTop: 4,
              }}
              // Optional styling
            >
              Member
            </Typography>

            {members?.map((item) =>
              item.typePosition === "Member" ? (
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          xl: "14px",
                        },
                        // marginTop: 4,
                        // marginBottom: 1
                      }}
                      // Optional styling
                    >
                      {item.tags}
                    </Typography>
                  </li>
                </ul>
              ) : (
                ""
              )
            )}
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Iconify
            icon="pajamas:substitute"
            width={24}
            sx={{ color: "#757575" }}
          />
          <Box
            sx={{
              fontSize: "0.875rem",
              maxWidth: { xs: "12.5rem", md: "11rem", xl: "14rem" },
              fontWeight: "400",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "14px",
                  xl: "16px",
                },
                fontWeight: "bold",
                // marginTop: 4,
              }}
              // Optional styling
            >
              Substitute
            </Typography>

            {members?.map((item) =>
              item.typePosition === "Substitute" ? (
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          xl: "14px",
                        },
                      }}
                      // Optional styling
                    >
                      {item.tags}
                    </Typography>
                  </li>
                </ul>
              ) : (
                ""
              )
            )}
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
  return (
    <Grid container spacing={3}>
      <Grid xs={12} sm={5} md={5} lg={4}>
        <Stack spacing={3}>
          {renderFollows}

          {renderAbout}
        </Stack>
      </Grid>

      <Grid xs={12} sm={7} md={7} lg={8}>
        <Stack spacing={3}>
          <FeedItemProfile
            onQueryUpdate={fetchLLMResponses}
            handleSort={handleSort}
            sortCriteria={sortCriteria}
            sortOrder={sortOrder}
            LLMOutput={LLMOutput}
            tabValues={tabValues}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

ProfileHome.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
};
